import request from '@/untils/request'
import axios from "axios";
function send_email(data) {
  let form = {
    customer_name: data.name,
    admin_email: "inpaikitchen2020@gmail.com",
    email: data.email,
    phone: data.phone,
    content: data.order_num != "" ? data.order_num + ":" : "",
    website_url: window.location.href,
  };
  return axios
    .post("https://api.lytc.pro/api/email-service/contact", form)
    .then((res) => {
      return res;
    })
}
function ppadmin_data(url) {
  const baseApiUrl = url
    ? `${url}/api/v2/ft_theme/77com`
    : `/api/v2/ft_theme/77com`;
  return request
    .get(baseApiUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("请求出错", error);
    });
}
export default {
  send_email,
  ppadmin_data,
};
