import axios from "axios";

let baseURL;
if (typeof window === "undefined") {
  // 服务器端环境
  baseURL = "http://localhost:33009/api";
} else {
  // 客户端环境
  baseURL = "/api";
}
const instance = axios.create({
  baseURL: process.env.VUE_APP_LOCAL === "true" ? baseURL : "",
  timeout: 60000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
