<template>
  <div id="index">
    <div class="">
      <div class="brands" ref="brands">
        <ul>
          <li
            v-for="(item, index) in brands"
            :key="index"
            :ref="item.ename + index"
          >
            <div class="logo">
              <img :src="item.logo" :alt="item.name" />
            </div>
            <div class="title">{{ item.subinfo }}</div>
          </li>
        </ul>
      </div>
      <div class="canvas-box" ref="canvas_box">
        <!-- 遮罩层 -->

        <canvas
          ref="myCanvas"
          :height="viewDevice != 'mobile' ? '1200' : '1500'"
          :width="viewDevice != 'mobile' ? '1400' : '920'"
        ></canvas>
      </div>
    </div>

    <div class="carousel-container" v-show="viewDevice == 'mobile'">
      <!-- 轮播 -->
      <div class="swiper-container" id="carousel">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in brands"
            :key="index"
          >
            <div class="item" @click="toContact(item.link)">
              <div class="logo">
                <img :src="item.logo" :alt="item.name" />
              </div>
              <div class="title">{{ item.subinfo }}</div>
              <div>
                <svg
                  t="1704960043777"
                  class="icon"
                  viewBox="0 0 3754 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="8300"
                >
                  <path
                    d="M42.666667 387.584h3105.621333v170.666667H42.666667v-170.666667z"
                    fill="#030D1D"
                    p-id="8301"
                  ></path>
                  <path
                    d="M3072 69.973333l682.666667 402.944-682.666667 428.373334z"
                    fill="#030D1D"
                    p-id="8302"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- 导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import windowSize from "@/mixins/windowSize";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
// 在 main.js 或 app.js
if (typeof window !== "undefined") {
  // const createjs = require("createjs-npm");
  // window.createjs = createjs; // 添加到window对象
  const createjs = require("createjs-npm");
}
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  mixins: [windowSize],
  data() {
    return {
      mask: null,
      stage: null,
      current: 1,
      mySwiper: null,
      builds: {}, //建筑引用
    };
  },
  computed: {
    ...mapGetters(["brands", "canvas", "v_width", "v_height"]),
    ...mapState(["loading"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.loading && typeof window !== "undefined") {
        this.initCanvas();
        this.newSwiper();
        let timer = setTimeout(() => {
          if (this.loading && typeof window !== "undefined") {
            this.move_Canvas(this.current);
          }
          clearTimeout(timer);
        }, 1000);
      }
    });
  },
  watch: {
    // loading: {
    //   handler(newVal, oldVal) {
    //     if (newVal && typeof window !== "undefined") {
    //       this.initCanvas();
    //       console.log("move_canvas+_loading=true")
    //       this.newSwiper();
    //     }
    //   },
    // },
    v_width(newVal, oldVal) {
      let timer = setTimeout(() => {
        if (this.loading && typeof window !== "undefined") {
          this.move_Canvas(this.current);
        }
        clearTimeout(timer);
      }, 1000);
      if (this.loading && typeof window !== "undefined") {
        var scale = newVal / this.stage.canvas.width; // 应使用宽度作为缩放依据

        // 设置舞台的注册点为中心
        this.stage.regX = this.stage.canvas.width / 2;
        this.stage.regY = this.stage.canvas.height / 2;

        // 更新舞台的位置，以保证中心不变
        this.stage.x = this.stage.regX;
        this.stage.y = this.stage.regY;

        createjs.Tween.get(this.stage).to(
          { scaleX: scale, scaleY: scale }, // 使用同样的比例缩放
          100,
          createjs.Ease.getPowInOut(4)
        );
        this.stage.update();
      }
    },
    v_height(newVal, oldVal) {
      if (this.loading && typeof window !== "undefined") {
        var scale = (newVal / this.stage.canvas.width) * 1.5; // 应使用宽度作为缩放依据

        // 设置舞台的注册点为中心
        this.stage.regX = this.stage.canvas.width / 2;
        this.stage.regY = this.stage.canvas.height / 2;

        // 更新舞台的位置，以保证中心不变
        this.stage.x = this.stage.regX;
        this.stage.y = this.stage.regY;

        createjs.Tween.get(this.stage).to(
          { scaleX: scale, scaleY: scale }, // 使用同样的比例缩放
          100,
          createjs.Ease.getPowInOut(4)
        );
        this.stage.update();
      }
    },
    current: {
      immediate: true,
      handler(newVal, oldVal) {
        if (typeof window !== "undefined") {
          this.move_Canvas(newVal, "after");
        }
      },
    },
    viewDevice: {
      handler(newVal, oldVal) {
        if (typeof window !== "undefined") {
          this.$nextTick(() => {
            this.initCanvas();
            // console.log("move_canvas+_viewDevice")
            this.newSwiper();
          });
        }
      },
    },
  },
  methods: {
    ...mapActions(["toContact"]),
    // 轮播图
    newSwiper() {
      var _this = this;
      this.mySwiper = new Swiper(`#carousel`, {
        autoplay: false,
        loop: true,
        observeParents: true,
        observer: true,
        ResizeObserverSize: true,
        simulateTouch: true, // 允许鼠标模拟触摸
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        on: {
          slideChange: function () {
            _this.current = this.activeIndex;
          },
        },
      });
    },
    // 初始化canvas
    initCanvas() {
      if (!this.brands && this.$refs.myCanvas) {
        return;
      }
      // 创建舞台对象
      this.stage = new createjs.Stage(this.$refs.myCanvas);
      this.stage.enableMouseOver(10);
      this.stage.mouseMoveOutside = true;
      // 设置背景
      let bg = new createjs.Bitmap(this.canvas.bg);

      if (this.canvas.bg && this.canvas.bg != "") {
        bg.image.onload = () => {
          this.$nextTick(() => {
            var imgW = bg.image.naturalWidth || bg.image.width;
            var imgH = bg.image.naturalHeight || bg.image.height;
            bg.x = (this.stage.canvas.width - imgW * bg.scaleX) / 2;
            bg.y = (this.stage.canvas.height - imgH * bg.scaleY) / 2.8;
            this.stage.addChild(bg);
            this.stage.setChildIndex(bg, 0);
          });
        };
      }
      if (this.canvas && this.canvas.deco) {
        // 添加装饰
        this.canvas.deco.forEach((e) => {
          let decoContainer = this.create_deco(e);
          this.stage.addChild(decoContainer);
        });
      }

      if (this.brands) {
        // 品牌建筑
        this.brands.forEach((element, index) => {
          let imgContainer = this.create_build(
            element.build,
            element.ename + index,
            element.link
          );
          if (imgContainer != null && this.stage) {
            imgContainer.item = index;
            this.stage.addChild(imgContainer);
            this.builds[imgContainer.item] = imgContainer; //添加坐标式的引用方式
            this.stage.update();
          }
        });
      }

      // 遮罩层
      if (this.viewDevice == "mobile") {
        this.mask = new createjs.Shape();
        // 黄色
        // this.mask.graphics
        //   .beginFill("rgba(254, 216, 0,0.5)")
        //   .drawRect(0, 0, this.stage.canvas.height, this.stage.canvas.width);
        // 白色
        this.mask.graphics
          .beginFill("rgba(255, 255, 255,0.5)")
          .drawRect(
            0,
            0,
            this.stage.canvas.width * 2,
            this.stage.canvas.height
          );
      } else {
        this.mask = new createjs.Shape();
        // 黄色
        // this.mask.graphics
        //   .beginFill("rgba(254, 216, 0,0.5)")
        //   .drawRect(0, 0, this.stage.canvas.width, this.stage.canvas.height);
        // 白色
        this.mask.graphics
          .beginFill("rgba(255, 255, 255,0.5)")
          .drawRect(0, 0, this.stage.canvas.width, this.stage.canvas.height);
      }

      this.mask.alpha = this.viewDevice != "mobile" ? 0 : 1; // 初始时不可见
      this.stage.addChild(this.mask);

      // 一直更新舞台
      createjs.Ticker.addEventListener("tick", this.stage);
    },

    // 创建canvas元素
    create_build(item, ref, link) {
      // console.log(link);
      if (item.img && item.img != "") {
        try {
          const container = new createjs.Container();
          container.originalIndex = parseFloat(item.index);

          // console.log(container);
          const image = new createjs.Bitmap(item.img);
          // console.log(item.img);
          // console.log(image.image);
          // 设置图片位置和缩放比例
          image.x = parseFloat(item.ix);
          image.y = parseFloat(item.iy);
          image.scaleX = image.scaleY = parseFloat(item.scale);
          // 跨域图像处理
          image.image.crossOrigin = "Anonymous"; // 添加这行代码
          // 设置原始尺寸
          image.image.onload = () => {
            image.regX = parseFloat(image.image.width) / 2;
            image.regY = parseFloat(image.image.height) / 2;
            image.width = parseFloat(image.image.width);
            image.height = parseFloat(image.image.height);

            if (this.viewDevice != "mobile") {
              // 鼠标悬停事件
              container.on("mouseover", () => {
                this.$refs.myCanvas.classList.add("hand-cursor");

                // 使用Tween来渐变遮罩层的透明度
                createjs.Tween.get(this.mask)
                  .to({ alpha: 1 }, 0, createjs.Ease.getPowInOut(4))
                  .wait(0); // 遮罩层消失前等待0.5秒
                // this.mask.alpha = 1;

                this.stage.setChildIndex(container, this.stage.numChildren - 1);
                this.stage.setChildIndex(this.mask, this.stage.numChildren); // 添加遮罩层，但保留当前图像在最上层
                if (
                  this.$refs.brands &&
                  this.$refs[ref] &&
                  this.$refs[ref][0]
                ) {
                  this.$refs.brands.style.display = "block";
                  // 显示品牌
                  this.$refs[ref][0].style.zIndex = 11;
                  this.$refs[ref][0].style.top = item.ty + "px";
                  this.$refs[ref][0].style.left = item.tx + "px";
                  this.$refs[ref][0].style.visibility = "visible";
                }

                // 放大图片
                createjs.Tween.get(image)
                  .to(
                    {
                      scaleX: parseFloat(item.scale) + 0.3,
                      scaleY: parseFloat(item.scale) + 0.3,
                    },
                    500,
                    createjs.Ease.getPowInOut(4)
                  )
                  .call(() => {}); // 500毫秒内放大到1.5倍，'4'是缓动强度
              });

              // 鼠标移出
              container.on("mouseout", () => {
                this.$refs.myCanvas.classList.remove("hand-cursor");

                // this.mask.alpha = 0;
                // 回到原来的层级
                this.stage.setChildIndex(container, item.index);
                // 使用Tween来渐变遮罩层的透明度
                createjs.Tween.get(this.mask).to(
                  { alpha: 0 },
                  100, // 100毫秒内透明度变为0
                  createjs.Ease.getPowInOut(4)
                );
                this.stage.update();
                if (
                  this.$refs.brands &&
                  this.$refs[ref] &&
                  this.$refs[ref][0]
                ) {
                  this.$refs.brands.style.display = "none";
                  this.$refs[ref][0].style.zIndex = "";
                  this.$refs[ref][0].style.visibility = "hidden";
                }

                // 缩小图片
                createjs.Tween.get(image).to(
                  {
                    scaleX: parseFloat(item.scale),
                    scaleY: parseFloat(item.scale),
                  },
                  500,
                  createjs.Ease.getPowInOut(4)
                ); // 500毫秒内缩小到原始大小
              });

              if (link != "") {
                container.on("click", () => {
                  window.open(link);
                });
              }
            }
            // 验证stage和canvas是否存在
            container.addChild(image);
          };
          if (container) {
            return container;
          }

          image.image.onerror = (errorEvent) => {
            console.error(`图片加载失败: ${item.img}`, errorEvent);
            // 可以在此处添加发送错误报告到服务器的代码
            return null;
          };
        } catch (error) {
          console.log("创建失败", error);
          return null;
        }
      } else {
        console.log("创建失败", error);
        return null;
      }
    },
    create_deco(item) {
      const container = new createjs.Container();
      const image = new createjs.Bitmap(item.img);
      container.originalIndex = parseFloat(item.index);
      // 设置图片位置和缩放比例
      image.x = parseFloat(item.ix);
      image.y = parseFloat(item.iy);
      image.scaleX = image.scaleY = parseFloat(item.scale);
      // 跨域图像处理
      image.image.crossOrigin = "Anonymous"; // 添加这行代码
      // 设置原始尺寸
      image.image.onload = () => {
        image.regX = parseFloat(image.image.width) / 2;
        image.regY = parseFloat(image.image.height) / 2;
        image.width = parseFloat(image.image.width);
        image.height = parseFloat(image.image.height);

        // 创建颤抖效果
        createjs.Tween.get(image, { loop: true })
          .to({ x: image.x + 2, y: image.y + 2 }, 500, createjs.Ease.quadInOut) // 向右轻微移动
          .to({ x: image.x - 2, y: image.y - 2 }, 500, createjs.Ease.quadInOut) // 向左轻微移动
          .to({ x: image.x, y: image.y }, 500, createjs.Ease.quadInOut); // 回到原位

        container.addChild(image);
      };

      return container;
    },
    move_Canvas(newVal, type) {
      this.$nextTick(() => {
        // if (!this.stage && typeof window !== "undefined") {
        //   this.initCanvas();
        //   console.log("move_canvas+_inite")
        // }

        if (
          this.viewDevice == "mobile" &&
          this.stage &&
          this.loading &&
          typeof window !== "undefined"
        ) {
          // 高亮当前的品牌之间，将其他都至于暗部
          Object.values(this.builds).forEach((container, index) => {
            let item = this.brands[index];
            this.stage.setChildIndex(container, container.originalIndex);
            createjs.Tween.get(container)
              .to(
                {
                  scaleX: parseFloat(item.build.scale),
                  scaleY: parseFloat(item.build.scale),
                },
                500,
                createjs.Ease.getPowInOut(4)
              )
              .call(() => {
                this.stage.update();
              });
          });

          //获取高亮相应的品牌建筑
          let index = newVal - 1;
          if (newVal - 1 >= this.brands.length) index = 0;
          if (newVal == -1 || newVal - 1 < 0) index = this.brands.length - 1;

          // 获取当前品牌
          let item = this.brands[index];
          // 获取画布对于的品牌建筑
          let container = this.builds[index];

          if (!item) return;
          let scale = 0.6;

          // 为舞台的缩放和位置添加动画
          createjs.Tween.get(this.stage)
            .to(
              { scaleX: scale, scaleY: scale },
              100,
              createjs.Ease.getPowInOut(4)
            )
            .call(() => {
              if (container.getChildAt(0)) {
                // 元素原始中心坐标
                let originalCenterX =
                  item.build.ix + container.getChildAt(0).width / 2;
                let originalCenterY =
                  item.build.iy + container.getChildAt(0).height / 2;
                // 放大后元素的中心坐标
                let scaledCenterX = originalCenterX * scale;
                let scaledCenterY = originalCenterY * scale;

                // 舞台中心坐标
                let stageCenterX = this.stage.canvas.width / 2;
                let stageCenterY = this.stage.canvas.height / 2;

                // 计算舞台新的 x 和 y 坐标
                let x = stageCenterX - scaledCenterX - scale * 200;
                let y =
                  stageCenterY -
                  scaledCenterY -
                  scale * container.getChildAt(0).height * (2 + scale);
                // let x =
                //   stageCenterX - scaledCenterX - (scale * this.v_width) / 1.8;
                // let y =
                //   stageCenterY -
                //   scaledCenterY -
                //   scale * this.v_width * scale +
                //   this.v_height / (scale * 10);

                if (type == "first") {
                  x += this.v_width / 2;
                  y += this.v_width / (2 + scale / 2);
                  createjs.Tween.get(container)
                    .to(
                      {
                        scaleX: parseFloat(item.build.scale) + 0.2,
                        scaleY: parseFloat(item.build.scale) + 0.2,
                      },
                      500,
                      createjs.Ease.getPowInOut(4)
                    )
                    .call(() => {
                      this.stage.update();
                    });
                }

                // 移动舞台
                createjs.Tween.get(this.stage).to(
                  { x, y },
                  500,
                  createjs.Ease.getPowInOut(4)
                );

                this.stage.setChildIndex(container, this.stage.numChildren - 1);
                this.stage.setChildIndex(this.mask, this.stage.numChildren); // 添加遮罩层，但保留当前图像在最上层
                if (type == "first") {
                  this.stage.setChildIndex(
                    this.mask,
                    this.stage.numChildren - 3
                  );
                  this.stage.setChildIndex(container, this.stage.numChildren);
                }
                this.stage.update();
              }
            })
            .wait(0);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#index {
  font: inherit;
  vertical-align: baseline;
  cursor: initial;
  width: 100%;
  overflow: hidden;
  &::before {
    content: "";
    // background-color: @primary-color;
    background-color: #fff;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .container {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .canvas-box {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .mobile({
      height: 95vh;
    });
  }
  canvas {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 0 auto;
  }
  .hand-cursor {
    cursor: pointer;
  }
  .brands {
    position: absolute;
    color: #461200;
    letter-spacing: 0.05em;
    z-index: 11;
    display: none;
    pointer-events: none;
    ul {
      .flex-mixin(row,flex-start,center);
      flex-wrap: wrap;
      pointer-events: none;

      li {
        background-color: rgba(255, 255, 255, 0.83);
        border-radius: 50%;
        width: 14em;
        aspect-ratio: 1/1;
        .flex-mixin(column,center,center);
        position: absolute;
        visibility: hidden;
        div {
          width: 80%;
          text-align: center;
        }
        img {
          max-width: 80%;
          position: relative;
          top: -1em;
        }
      }
    }
  }
  .carousel-container {
    // background-color: #ccc;
    width: 100%;
    height: 100vh;
    padding-top: 33vh;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    .swiper-container {
      padding: 0 !important;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .swiper-slide {
      box-sizing: border-box;
      .item {
        max-width: 14em;
        aspect-ratio: 1/1;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
        color: #461200;
        .flex-mixin(column,center,center);
        .logo {
          margin: 0 auto;
          text-align: center;
          max-width: 60%;

          img {
            width: 100%;
            position: relative;
            top: -1em;
          }
        }
        svg {
          width: 35px;
          position: relative;
          bottom: -1.1em;
          path {
            fill: #461200;
          }
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 5em;
      height: 5em;
      background-color: #fff;
      display: block;
      border-radius: 50%;
      color: #fff;
      line-height: 0.5em;
      text-align: center;
      transition: all 0.3s ease-in-out;
      left: -10%;
      top: 30%;
      &::after {
        font-size: 1em;
        font-weight: bold;
        line-height: 5em;
        color: #461200;
        position: relative;
        left: 1em;
      }
      &:hover {
        border-color: @primary-color;
        color: @primary-color;
      }
    }
    .swiper-button-next {
      left: unset;
      right: -10%;
      &::after {
        left: unset;
        right: 1em;
      }
    }
  }
}
</style>
