
import { mapState } from 'vuex';

export default {
  data(){
    return{
      viewDevice:"web",
    }
  },
  computed: {
    ...mapState({
      windowDimensions: (state) => state.windowDimensions,
    })
  },
  watch:{
    windowDimensions:{
      immediate:true,
      handler(newVal,oldVal){
        if(newVal.width<=600&&newVal.width!=0){
          this.viewDevice = 'mobile'
        }else if(newVal.width>=601&&newVal.width<=1111){
          this.viewDevice = 'tablet'
        }else{
          this.viewDevice = 'web'
        }
      }
    }
  }
};
