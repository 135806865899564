var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('header',{staticClass:"container"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"loading":"lazy","src":require("../assets/images/logo.jpg")}})])],1),_vm._v(" "),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"box",class:{ show_menu: _vm.show_menu },on:{"click":function($event){_vm.show_menu = !_vm.show_menu}}},[_c('span',{staticClass:"line l"}),_vm._v(" "),_c('span',{staticClass:"line"}),_vm._v(" "),_c('span',{staticClass:"line r"})])]),_vm._v(" "),_c('div',{ref:"menu",staticClass:"menu",class:{ show: _vm.show_menu }},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"animate__animated",class:{
          animate__fadeInLeft: _vm.show_menu,
          animate__fadeOutLeft: !_vm.show_menu,
        }},_vm._l((_vm.brands),function(item,index){return _c('router-link',{key:index,attrs:{"to":'/brands/' + item.name}},[_c('li',{on:{"click":function($event){_vm.show_menu = false}}},[_c('img',{attrs:{"loading":"lazy","src":item.slogo,"alt":item.name}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))])])])}),1)]),_vm._v(" "),_c('div',{staticClass:"right animate__animated"},[_c('div',{staticClass:"top animate__animated",class:{
          animate__fadeIn: _vm.show_menu,
          animate__fadeOut: !_vm.show_menu,
        }},[_c('ul',_vm._l((_vm.menu),function(item,index){return _c('router-link',{key:index,attrs:{"to":item.route}},[_c('li',{on:{"click":function($event){_vm.show_menu = false}}},[_c('span',[_vm._v(_vm._s(item.title))])])])}),1)]),_vm._v(" "),_c('div',{staticClass:"bottom animate__animated",class:{
          animate__fadeIn: _vm.show_menu,
          animate__fadeOut: !_vm.show_menu,
        }},[_c('div',{staticClass:"tips"},[_vm._v("FOLLOW US")]),_vm._v(" "),_c('div',[_c('ul',{staticClass:"brand"},_vm._l((_vm.brands),function(item,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('ul',{staticClass:"contact"},_vm._l((item.contact),function(e,key){return _c('li',{key:key},[_c('contact',{attrs:{"type":key,"link":e}})],1)}),0)])}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }