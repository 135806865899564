import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import view from "@/views/index.vue";
// import brands from "@/views/brands";
// import news from "@/views/news";
// import privacy from "@/views/privacy";
// import contact from "@/views/contact";
const brands = () => import("@/views/brands");
const news = () => import("@/views/news");
const privacy = () => import("@/views/privacy");
const contact = () => import("@/views/contact");
const routes = [
  {
    path: "/",
    component: view,
  },
  {
    path: "/brands/:name",
    component: brands,
  },
  {
    path: "/news",
    component: news,
  },
  {
    path: "/privacy",
    component: privacy,
  },
  {
    path: "/contact",
    component: contact,
  },
];
// 路由配置
// const router = new VueRouter({
//   routes,
//   mode: "history",
//   scrollBehavior(to, from, savedPosition) {
//     // 始终滚动到顶部
//     if (savedPosition) {
//       return savedPosition;
//     } else {
//       return {
//         x: 0,
//         y: 0,
//       };
//     }
//   },
// });

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

// export default router;
export default function createRouter() {
  const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  // 如果你需要自定义 push 方法，也可以在这里进行
  const originalPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
  };

  return router;
}
