import Vue from "vue";
import App from "./App.vue";
// import router from './router'
// import store from './store'

// ssr
import createRouter from "./router";
import createStore from "./store";
import { sync } from "vuex-router-sync";
//动画效果
import "animate.css";

// 表单验证
import { ValidationProvider, ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// import "@/untils/formValidate"

if (typeof window !== "undefined") {
  // 检查是否在客户端环境中
  require("@/untils/formValidate");
}


//使用meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});
// 结束
Vue.config.productionTip = false;
// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')
export default function createApp() {
  const router = createRouter();
  const store = createStore();
  sync(store, router);
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });
  return {
    app,
    router,
    store,
  };
}
