<template>
  <div id="app">
    <div>
      <layout />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import layout from "@/components/layout";
import windowSize from "@/mixins/windowSize";
import throttle from "@/untils/throttle";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  mixins: [windowSize],
  components: {
    layout,
  },
  data() {
    return {
      webmeta: {
        meta_description: "",
        meta_keywords: "",
        og_description: "",
        og_image: "",
        og_title: "",
        og_url: "",
      },
    };
  },
  computed: {
    ...mapGetters(["ga_id", "ads", "mc", "ga_state", "seo"]),
  },
  metaInfo() {
    return {
      title: this.webmeta.og_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.webmeta.meta_description,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.webmeta.meta_keywords,
        },
        { vmid: "url", property: "og:url", content: this.webmeta.og_url },
        {
          vmid: "title",
          property: "og:title",
          content: this.webmeta.og_title,
        },
        {
          vmid: "og_description",
          property: "og:description",
          content: this.webmeta.og_description,
        },
        {
          vmid: "image",
          property: "og:image",
          content: this.webmeta.og_image,
        },
      ],
    };
  },
  async serverPrefetch() {
    const url =
      process.env.VUE_APP_LOCAL === "true"
        // ? "https://dogcatstar.lytc0425.uk"
        ? "https://open.ktg-health.com"
        : this.$ssrContext.clientUrl;

    await Promise.all([this.$store.dispatch("updatePpadminData", url)]);
    this.$store.state.loading = true;
    this.webmeta = this.seo;
    // console.log(this.seo)
  },
  async mounted() {
    this.$nextTick(async () => {
      // 插入版本注释
      if (typeof window !== "undefined") {
        var comment = document.createComment(
          "版本号: " + process.env.VUE_APP_VERSION
        );
        var headTag = document.head;
        headTag.insertBefore(comment, headTag.firstChild);
      }
      // 绑定窗口大小事件
      if (typeof window !== "undefined") {
        window.addEventListener("resize", throttle(this.initDimensions, 200));
        window.addEventListener("scroll", this.initWheelPostions);
        this.initDimensions(); // 初始化
        this.initWheelPostions();
      }
      if (!this.$store.state.loading) {
        await this.updatePpadminData();
      }

      if (typeof window !== "undefined") {
        let script1 = document.createElement("script");
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${this.ga_id}`;
        document.head.appendChild(script1);

        let script2 = document.createElement("script");
        script2.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${this.ga_id}');
              gtag('config', '${this.ads?.id}');
              gtag('event', 'conversion', {'send_to': '${this.ads?.browse}'});
          `;
        document.head.appendChild(script2);
        // Microsoft Clarity
        let script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.innerHTML = this.mc;
        document.head.appendChild(script3);
        this.webmeta = this.seo;
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.initWheelPostions);
  },
  methods: {
    ...mapActions(["updatePpadminData"]),
    // 初始化窗口大小
    initDimensions() {
      requestAnimationFrame(() => {
        this.$store.commit("SET_WINDOW_DIMENSIONS", {
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    },
    // 初始化当前鼠标滚动的x,y轴数据
    initWheelPostions() {
      window.requestAnimationFrame(() => {
        this.$store.commit("SET_SCROLL_POSITION", {
          x: window.scrollX,
          y: window.scrollY,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: @f-tb;
}
</style>
