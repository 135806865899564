import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import service from "./service";
Vue.use(Vuex);
// export default new Vuex.Store({
//   state: {
//     ppadmin_data: {
//       0: {
//         type: "canvas",
//         data: {
//           bg: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/61917184-7724-41d7-7087-bf4b27a67200/introduce",
//           deco: [
//             {
//               img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
//               ix: 100,
//               iy: 200,
//               scale: 1,
//               index: 1,
//               move: true,
//             },
//             {
//               img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
//               ix: 950,
//               iy: 460,
//               scale: 1,
//               index: 2,
//               move: false,
//             },
//           ],
//         },
//       },
//       1: {
//         type: "brands",
//         data: [
//           {
//             build: {
//               img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/10b577cc-ed9d-497b-56b1-3e26af55e000/general",
//               ix: 1200,
//               iy: 500,
//               tx: 1000,
//               ty: 250,
//               scale: 1,
//               index: 1,
//             },
//             logo: "https://www.77.com.tw/assets/img/logo-milkPlus.png",
//             slogo: "https://www.77.com.tw/assets/img/icon-nav-milkPlus.svg",
//             dlogo:
//               "https://www.77.com.tw/assets/img/img-brand-milkPlus-main.png",
//             name: "本味誠現",
//             ename: "rujia",
//             subinfo: "越嚼越香的滋味",
//             subinfo2:
//               "秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。",

//             link: "https://dogcatstar.lytc0425.uk/",
//             contact: {
//               fb: "https://www.facebook.com/goodmagic.tw/",
//               line: "https://line.me/R/ti/p/@564ictyk",
//               youtube: "https://line.me/R/ti/p/@564ictyk",
//               ins: "https://line.me/R/ti/p/@564ictyk",
//             },
//             info: [
//               {
//                 title: "口感豐富的優質巧克力",
//                 desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
//                 img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
//               },
//               {
//                 title: "口感豐富的優質巧克力",
//                 desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
//                 img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
//               },
//             ],
//           },
//           {
//             build: {
//               img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
//               ix: 500,
//               iy: 200,
//               tx: 500,
//               ty: 200,
//               scale: 1,
//               index: 1,
//             },
//             logo: "https://www.77.com.tw/assets/img/img-logo-brand-milano-m.png",
//             slogo:
//               "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/10b577cc-ed9d-497b-56b1-3e26af55e000/general",
//             dlogo:
//               "https://www.77.com.tw/assets/img/img-brand-shortbread-main.png",
//             name: "乳加1",
//             ename: "rujia1",
//             subinfo: "越嚼越香的滋味1",
//             subinfo2: "秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。",

//             link: "https://dogcatstar.lytc0425.uk/",
//             contact: {
//               fb: "fb-link",
//               line: "line-link",
//               youtube: "youtube-link",
//               ins: "ins-link",
//             },
//             info: [
//               {
//                 title: "口感豐富的優質巧克力",
//                 desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
//                 img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
//               },
//             ],
//           },
//         ],
//       },
//       2: {
//         type: "menu",
//         data: [
//           {
//             title: "最新消息",
//             route: "/news",
//             content: `需要協助？歡迎來電客服專線，或留下聯絡資訊，我們將盡速為您服務。
//             客服專線0800-060-515

//             服務時間週一至週五 09:00-17:30

//             公司地址23147 新北市新店區北新路一段86號20樓之6`,
//           },
//           {
//             title: "联络我们",
//             route: "/contact",
//             content: "联络我们",
//           },
//           {
//             title: "隐私政策",
//             route: "/privacy",
//             content: "隐私政策",
//           },
//         ],
//       },
//     },
//     //屏幕尺寸
//     windowDimensions: {
//       width: 0,
//       height: 0,
//     },
//     //鼠标滚动事件
//     scrollPosition: {
//       x: 0,
//       y: 0,
//     },
//     loading:false,
//   },
//   actions: {
//     toContact({ state }, payload) {
//       window.location.href = payload;
//     },
//     async updatePpadminData({ commit }, url) {
//       try {
//         let res = await service.ppadmin_data(url);
//         if (res.data) {
//           commit("SET_PPADMIN_DATA", res.data);
//         }
//         return res;
//       } catch (error) {
//         console.log("请求出错啦~~vuex");
//       }
//     },
//   },
//   mutations: {
//     //设置屏幕尺寸
//     SET_WINDOW_DIMENSIONS(state, payload) {
//       state.windowDimensions = payload;
//     },
//     // 设置wheel位置
//     SET_SCROLL_POSITION(state, payload) {
//       state.scrollPosition = payload;
//     },
//     // Mutations for ppadmin_data
//     SET_PPADMIN_DATA(state, payload) {
//       state.ppadmin_data = payload;
//     },
//   },

//   getters: {
//     v_width(state) {
//       return state.windowDimensions.width;
//     },
//     v_height(state) {
//       return state.windowDimensions.height;
//     },
//     scrollX(state) {
//       return state.scrollPosition.x;
//     },
//     scrollY(state) {
//       return state.scrollPosition.y;
//     },
//     canvas(state) {
//       for (let key in state.ppadmin_data) {
//         if (state.ppadmin_data.hasOwnProperty(key)) {
//           let item = state.ppadmin_data[key];
//           if (item.type == "canvas") {
//             return item.data;
//           }
//         }
//       }
//       return {};
//     },
//     brands(state) {
//       for (let key in state.ppadmin_data) {
//         if (state.ppadmin_data.hasOwnProperty(key)) {
//           let item = state.ppadmin_data[key];
//           if (item.type == "brands") {
//             return item.data;
//           }
//         }
//       }
//       return [];
//     },
//     menu(state) {
//       for (let key in state.ppadmin_data) {
//         if (state.ppadmin_data.hasOwnProperty(key)) {
//           let item = state.ppadmin_data[key];
//           if (item.type == "menu") {
//             return item.data;
//           }
//         }
//       }
//       return [];
//     },
//   },

//   modules: {},
//   plugins: [
//     createPersistedState({
//       storage: window.sessionStorage,
//     }),
//   ],
// });
export default function createStore() {
  const plugins = [];
  if (typeof window !== "undefined") {
    plugins.push(
      createPersistedState({
        storage: window.sessionStorage,
      })
    );
  }
  return new Vuex.Store({
    state: {
      ppadmin_data: {
        0: {
          type: "canvas",
          data: {
            bg: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/61917184-7724-41d7-7087-bf4b27a67200/introduce",
            deco: [
              {
                img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
                ix: 100,
                iy: 200,
                scale: 1,
                index: 1,
                move: true,
              },
              {
                img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
                ix: 950,
                iy: 460,
                scale: 1,
                index: 2,
                move: false,
              },
            ],
          },
        },
        1: {
          type: "brands",
          data: [
            {
              build: {
                img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/10b577cc-ed9d-497b-56b1-3e26af55e000/general",
                ix: 1200,
                iy: 500,
                tx: 1000,
                ty: 250,
                scale: 1,
                index: 1,
              },
              logo: "https://www.77.com.tw/assets/img/logo-milkPlus.png",
              slogo: "https://www.77.com.tw/assets/img/icon-nav-milkPlus.svg",
              dlogo:
                "https://www.77.com.tw/assets/img/img-brand-milkPlus-main.png",
              name: "本味誠現",
              ename: "rujia",
              subinfo: "越嚼越香的滋味",
              subinfo2:
                "秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。",

              link: "https://dogcatstar.lytc0425.uk/",
              contact: {
                fb: "https://www.facebook.com/goodmagic.tw/",
                line: "https://line.me/R/ti/p/@564ictyk",
                youtube: "https://line.me/R/ti/p/@564ictyk",
                ins: "https://line.me/R/ti/p/@564ictyk",
              },
              info: [
                {
                  title: "口感豐富的優質巧克力",
                  desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
                  img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
                },
                {
                  title: "口感豐富的優質巧克力",
                  desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
                  img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
                },
              ],
            },
            {
              build: {
                img: "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/c8a92706-6224-4561-1447-673796baad00/general",
                ix: 500,
                iy: 200,
                tx: 500,
                ty: 200,
                scale: 1,
                index: 1,
              },
              logo: "https://www.77.com.tw/assets/img/img-logo-brand-milano-m.png",
              slogo:
                "https://imagedelivery.net/i8Xoxd20xJp7bcbNAjF34A/10b577cc-ed9d-497b-56b1-3e26af55e000/general",
              dlogo:
                "https://www.77.com.tw/assets/img/img-brand-shortbread-main.png",
              name: "乳加1",
              ename: "rujia1",
              subinfo: "越嚼越香的滋味1",
              subinfo2:
                "秉持提供優質用料的品牌承諾，可以天天品嚐的優質巧克力。",

              link: "https://dogcatstar.lytc0425.uk/",
              contact: {
                fb: "fb-link",
                line: "line-link",
                youtube: "youtube-link",
                ins: "ins-link",
              },
              info: [
                {
                  title: "口感豐富的優質巧克力",
                  desc: "歐維氏醇黑系列使用100%迦納可可膏，以優質原料、天然成份，體現單一產區各種比例的可可風味。榛果威化棒結合濃郁榛果醬與威化餅，融合出令人驚豔的多層次豐富口感！",
                  img: "https://www.77.com.tw/assets/img/img-brand-always-m-1.jpg",
                },
              ],
            },
          ],
        },
        2: {
          type: "menu",
          data: [
            {
              title: "最新消息",
              route: "/news",
              content: `需要協助？歡迎來電客服專線，或留下聯絡資訊，我們將盡速為您服務。
                客服專線0800-060-515
    
                服務時間週一至週五 09:00-17:30
    
                公司地址23147 新北市新店區北新路一段86號20樓之6`,
            },
            {
              title: "联络我们",
              route: "/contact",
              content: "联络我们",
            },
            {
              title: "隐私政策",
              route: "/privacy",
              content: "隐私政策",
            },
          ],
        },
      },
      //屏幕尺寸
      windowDimensions: {
        width: 0,
        height: 0,
      },
      //鼠标滚动事件
      scrollPosition: {
        x: 0,
        y: 0,
      },
      loading: false,
    },
    actions: {
      toContact({ state }, payload) {
        window.open(payload);
      },
      async updatePpadminData({ commit }, url) {
        try {
          let res = await service.ppadmin_data(url);
          if (res.data) {
            commit("SET_PPADMIN_DATA", res.data);
          }
          return res;
        } catch (error) {
          console.log("请求出错啦~~vuex");
        }
      },
    },
    mutations: {
      //设置屏幕尺寸
      SET_WINDOW_DIMENSIONS(state, payload) {
        state.windowDimensions = payload;
      },
      // 设置wheel位置
      SET_SCROLL_POSITION(state, payload) {
        state.scrollPosition = payload;
      },
      // Mutations for ppadmin_data
      SET_PPADMIN_DATA(state, payload) {
        state.ppadmin_data = payload;
      },
    },

    getters: {
      v_width(state) {
        return state.windowDimensions.width;
      },
      v_height(state) {
        return state.windowDimensions.height;
      },
      scrollX(state) {
        return state.scrollPosition.x;
      },
      scrollY(state) {
        return state.scrollPosition.y;
      },
      canvas(state) {
        for (let key in state.ppadmin_data) {
          if (state.ppadmin_data.hasOwnProperty(key)) {
            let item = state.ppadmin_data[key];
            if (item.type == "canvas") {
              return item.data;
            }
          }
        }
        return {};
      },
      brands(state) {
        for (let key in state.ppadmin_data) {
          if (state.ppadmin_data.hasOwnProperty(key)) {
            let item = state.ppadmin_data[key];
            if (item.type == "brands") {
              return item.data;
            }
          }
        }
        return [];
      },
      menu(state) {
        for (let key in state.ppadmin_data) {
          if (state.ppadmin_data.hasOwnProperty(key)) {
            let item = state.ppadmin_data[key];
            if (item.type == "menu") {
              return item.data;
            }
          }
        }
        return [];
      },
      ga_id(state) {
        if (state.ppadmin_data) {
          for (const key in state.ppadmin_data) {
            if (state.ppadmin_data[key].type == "ga_id") {
              return state.ppadmin_data[key].data;
            }
          }
        }
        return "";
      },
      ads(state) {
        if (state.ppadmin_data) {
          for (const key in state.ppadmin_data) {
            if (state.ppadmin_data[key].type == "ads") {
              return state.ppadmin_data[key].data;
            }
          }
        }
        return {
          id: "",
          buy: "",
        };
      },
      seo(state) {
        if (state.ppadmin_data) {
          for (const key in state.ppadmin_data) {
            if (state.ppadmin_data[key].type == "seo") {
              return state.ppadmin_data[key].data;
            }
          }
        }
        return {
          meta_description: "",
          og_title: "",
          og_url: "",
          meta_keywords: "",
          og_image: "",
          og_description: "",
        };
      },
      mc(state) {
        if (state.ppadmin_data) {
          for (const key in state.ppadmin_data) {
            if (state.ppadmin_data[key].type == "mc") {
              return state.ppadmin_data[key].data;
            }
          }
        }
        return "";
      },
      facebook(state) {
        if (state.ppadmin_data) {
          for (const key in state.ppadmin_data) {
            if (state.ppadmin_data[key].type == "facebook") {
              return state.ppadmin_data[key].data;
            }
          }
        }
        return "";
      },
     
    },
  });
}
