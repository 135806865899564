import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "不能為空哦",
});
extend("phone", {
  validate: (value) => /^[0-9]+$/.test(value), // 确保电话号码是10位数字
  message: "格式不正確", // 自定义的错误消息
});
extend("email", {
  validate: (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/.test(value),
  message: "格式不正確", // 自定义的错误消息
});
