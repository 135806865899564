<template>
  <div id="header">
    <header class="container">
      <!-- logo start-->
      <router-link to="/">
        <img loading="lazy" src="../assets/images/logo.jpg" class="logo" />
      </router-link>
      <!-- 100px*106px -->
      <!-- logo end-->
    </header>

    <div class="bar">
      <div
        class="box"
        :class="{ show_menu: show_menu }"
        @click="show_menu = !show_menu"
      >
        <span class="line l"></span>
        <span class="line"></span>
        <span class="line r"></span>
      </div>
    </div>

    <div class="menu" :class="{ show: show_menu }" ref="menu">
      <div class="left">
        <ul
          class="animate__animated"
          :class="{
            animate__fadeInLeft: show_menu,
            animate__fadeOutLeft: !show_menu,
          }"
        >
          <router-link
            :to="'/brands/' + item.name"
            v-for="(item, index) in brands"
            :key="index"
          >
            <li @click="show_menu = false">
              <img loading="lazy" :src="item.slogo" :alt="item.name" />
              <span>{{ item.name }}</span>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="right animate__animated">
        <div
          class="top animate__animated"
          :class="{
            animate__fadeIn: show_menu,
            animate__fadeOut: !show_menu,
          }"
        >
          <ul>
            <router-link
              :to="item.route"
              v-for="(item, index) in menu"
              :key="index"
            >
              <li @click="show_menu = false">
                <span>{{ item.title }}</span>
              </li>
            </router-link>
          </ul>
        </div>
        <div
          class="bottom animate__animated"
          :class="{
            animate__fadeIn: show_menu,
            animate__fadeOut: !show_menu,
          }"
        >
          <div class="tips">FOLLOW US</div>
          <div>
            <ul class="brand">
              <li v-for="(item, index) in brands" :key="index">
                <span>{{ item.name }}</span>
                <ul class="contact">
                  <li v-for="(e, key) in item.contact" :key="key">
                    <contact :type="key" :link="e" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import contact from "./contact.vue";
export default {
  components: {
    contact,
  },
  data() {
    return {
      show_menu: false,
    };
  },
  computed: {
    ...mapGetters(["brands", "menu"]),
  },
  watch: {
    show_menu(newVal, oldVal) {
      if (newVal) {
        document.body.style.overflowY = "hidden";
        if (this.$refs.menu) {
          this.$refs.menu.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
          });
        }
      } else {
        document.body.style.overflowY = ""; // 允许页面滚动
      }
    },
  },
};
</script>

<style lang="less" scoped>
#header {
  .logo {
    box-shadow: 0px 2px 5px 0.5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: none;

    img {
      pointer-events: all;
      cursor: pointer;
      border-end-start-radius: 5px;
      border-end-end-radius: 5px;
      .mobile({
            max-width:3.5em ;
        });
    }
  }
  .bar {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100vh;
    min-width: 6em;
    background: #fff;
    z-index: 1003;
    .flex-mixin(column,center,center);
    padding: 0 1.15em;
    .mobile({
       width: 100vw;
       height: 8vh;
       box-shadow: 0px -1px 5px 0.1px rgba(0,0,0,0.07);
    });
    .box {
      cursor: pointer;
      width: 100%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      border: 2px solid #461200;
      background-color: #fff;
      .flex-mixin(column,center,center);
      .mobile({
        width: 14%;
        position: absolute;
        top: -1.55em;
      });
      .line {
        display: block;
        border-bottom: 2px solid #461200;
        width: 45%;
        margin: 0.15em 0;
        position: relative;
        transition: all 0.2s linear;
      }
      .l::after,
      .r::after {
        content: "";
        display: inline-block;
        width: 2.5px;
        height: 2.5px;
        background-color: #fff;
        position: absolute;
        left: 0.35em;
      }
      .r::after {
        left: unset;
        right: 0.35em;
      }
      &:hover {
        .l::after {
          animation: moveAfter 0.45s infinite alternate;
        }
        .r::after {
          animation: moveAfterReverse 0.45s infinite alternate;
        }
      }
    }

    .show_menu {
      .line {
        visibility: hidden;
      }
      .r,
      .l {
        visibility: visible;
        transform: rotate(45deg) translate(4.5px, 4.5px);
        .mobile({
            width: 50%;
            transform: rotate(45deg) translate(4.8px, 4.8px);

        });
        &::after {
          visibility: hidden;
        }
      }
      .r {
        transform: rotate(-45deg) translate(4.5px, -4.5px);
        .mobile({
            width: 50%;
            transform: rotate(-45deg) translate(4.8px, -4.8px);

        });
      }
    }
    @keyframes moveAfter {
      0% {
      }
      100% {
        transform: translateX(0.65em);
      }
    }

    @keyframes moveAfterReverse {
      0% {
        // transform: translateX(0);
      }
      100% {
        transform: translateX(-0.65em);
      }
    }
  }
  .menu {
    position: fixed;
    z-index: 1002;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    left: -100%;
    transition: all 0.8s ease;
    // .flex-mixin(row,center,center);
    .web({.flex-mixin(row,center,center);});
    .tablet({.flex-mixin(row,center,center);});

    .mobile({
        left: unset;
        top: -120%;
        max-height: 92vh;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: 0;

        // 隐藏y轴滚动条
        &::-webkit-scrollbar {
            display: none;
        }
    });
    .left,
    .right {
      flex: 1;
      height: 100%;
      width: 100%;
      color: #461200;
    }
    .left {
      background-color: @primary-color;

      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: 0;

      // 隐藏y轴滚动条
      &::-webkit-scrollbar {
        display: none;
      }
      .flex-mixin(column,center,center);
      .mobile({
        flex: unset;
        height: auto;        
        box-sizing: content-box !important;
    
      });
      ul {
        padding-left: 20%;
        text-align: left;
        letter-spacing: 0.2em;
        animation-delay: 0.3s;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        .mobile({
            padding:2em 1em;
            height: auto;
        });
        li {
          position: relative;
          font-size: 3em;
          height: 1.5em;
          width: 150%;
          // background-color: #fff;
          margin: 0.2em;

          img {
            visibility: hidden;
            position: absolute;
            left: -1.2em;
          }
          span {
            display: inline-block;
            transition: all 0.2s linear;
          }
          &::after {
            content: "";
            transition: all 0.2s linear;
            display: block;
            position: absolute;
            bottom: 0;
            left: 30%;
            transform: translateX(-50%);
            background-color: rgba(255, 255, 255, 0.5);
            height: 60%;
            width: 0;
            z-index: -1;
            border-radius: 5em;
          }
        }
        li:hover {
          img {
            visibility: visible;
          }
          span {
            transform: translateX(1em);
          }
          &::after {
            width: 100%;
          }
        }
        .mobile({
            padding-left: unset;
            text-align: center;
            letter-spacing: 0.1em;
            height: 100%;
            width: 100%;
        li {
          font-size: 1.4em;
          margin-top:1.5em ;
          width: 100%;
          img {
            visibility: visible;
            max-width: 2.8em;
            left: 1.2em;
            top:-1.2em;
          }
          span {
            display: inline-block;
            transition: all 0.2s linear;
            transform:translate(0em,-0.1em);
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba(255, 255, 255, 0.5);
            height: 140%;
            width: 85%;
            z-index: -1;
            border-radius: 5em;
          }
        }
        li:hover {
          img {
            visibility: unset;
          }
          span {
            transform: unset;
          }
          &::after {
            width: unset;
          }
        }
      });
      }
    }
    .right {
      padding-left: 10em;
      flex: 1.2;
      .flex-mixin(column,center,flex-start);
      .mobile({
        padding-left: unset;
        padding: 1em;
        flex: unset;
        height: auto;
   
      });

      .top {
        padding-bottom: 4em;
        font-size: 1.5em;
        line-height: 2.5em;
        color: #461200;
        letter-spacing: 0.2em;
        animation-delay: 0.5s;
        .mobile({
            padding-bottom: 1em;
            margin-bottom: 1em;
            font-size: 1em;
            line-height: 2em;
            border-bottom: 1px solid #ccc;
        });
        ul {
          .mobile({
                .flex-mixin(row,center,center);
                flex-wrap: wrap;
            });
          li {
            cursor: pointer;
            position: relative;
            text-align: left;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0.4em;
              width: 0;
              height: 0.18em;
              display: inline-block;
              background-color: #461200;
              transition: all 0.2s linear;
            }
            &:hover {
              &::after {
                width: 100%;
              }
            }

            .mobile({
               padding: 0 1em ;
               &::after{
                display: none
               }

            });
          }
        }
      }
      .bottom {
        letter-spacing: 0.2em;
        animation-delay: 0.5s;

        .tips {
          font-size: 1.2em;
          margin-bottom: 0.8em;
          font-family: @f-tw;
        }
        .brand {
          .contact {
            position: absolute;
            left: 100%;
            bottom: 0.1em;
            // visibility: hidden;
            .flex-mixin(row, flex-start, flex-start);
            border-radius: 0.2em;

            li {
              line-height: 1.5em;
              height: 1.8em;
              margin: 0 0.2em;
            }
            .mobile({
                bottom: -0.1em;
                li {
                    margin: 0 0.5em;
                }
            });
          }
          > li {
            position: relative;
            height: 2em;
            line-height: 2em;
            margin: 0.5em 0;
            text-align: left;
            &:hover {
              span {
                transform: translateX(-1em);
              }
              .contact {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  .show {
    left: 0;
    transition: all 0.3s linear;

    .mobile({
        left: unset;
        top: 0;
    });
    .right {
      .bottom {
        animation-delay: 0;
      }
    }
  }
}
</style>
